







































































































































































































/* eslint-disable global-require */
import Vue from 'vue'
import { computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import VuexyLogo from '@/vuexy/layouts/components/Logo.vue'
import { required, email } from '@/vuexy/utils/validations/validations'
import { togglePasswordFunc } from '@/vuexy/mixins/ui/forms'
import { store } from '@/store/index'
import ToastificationContent from '@/vuexy/components/toastification/ToastificationContent.vue'

const sideImg = require('@/assets/images/pages/register-v2.svg')
const sideImgDark = require('@/assets/images/pages/register-v2-dark.svg')

export default Vue.extend({
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      // validation
      required,
      email,
    }
  },
  computed: {
  },
  methods: {
    validationForm() {
      const { registerForm }: any = this.$refs

      registerForm.validate()
        .then((success: boolean) => {
          if (success) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Form Submitted',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
          }
        })
    },
  },
  setup() {
    const {
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    } = togglePasswordFunc()

    const imgUrl = computed(() => {
      if (store.state.appConfig.layout.skin === 'dark') {
        return sideImgDark
      }

      return sideImg
    })

    return {
      imgUrl,
      passwordFieldType,
      passwordToggleIcon,
      togglePasswordVisibility,
    }
  },
})
/* eslint-disable global-require */
